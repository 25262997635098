import { Col, Row } from "react-bootstrap";
import { FormatEnumOption } from "../../Formatters";
import { GenericEnum } from "../../GenericEnum";
import { GenericError } from "../../GenericError";
import { GenericIcon } from "../../GenericIcon";
import { GenericInput } from "../../GenericInput";
import { TypeTemplateWrapper } from "../../graphical/TypeTemplateWrapper";
import { modService } from "../../ModService";
import { ResourceExplorer } from "../../ResourceExplorer";
import { AssistedViewProps } from "../AssistedView";
import { useCallback, useEffect, useState } from "react";
import "./ItemView.css";
import ImageDropdown from "../ImageDropdown/ImageDropdown";

/*
Torso: ui/ingame/inventory_i80.dds
Left: ui/ingame/inventory_i84.dds
Right: ui/ingame/inventory_i88.dds
Back: ui/ingame/inventory_i90.dds
Special: ui/ingame/inventory_i94.dds
Rarity Full: ui/ingame/itemrollover_i8e.dds
Rarity Empty: ui/ingame/itemrollover_i8f.dds
*/

const rarityFull = "lego-universe:ui/ingame/itemrollover_i8e.dds";
const rarityEmpty = "lego-universe:ui/ingame/itemrollover_i8f.dds";
const armourIcon = "lego-universe:ui/ingame/itemrollover_i20.dds";
const imaginationIcon = "lego-universe:ui/ingame/itemrollover_i25.dds";
const healthIcon = "lego-universe:ui/ingame/itemrollover_i2a.dds";

export const ItemView: React.FC<AssistedViewProps> = ({
    value, setValue, props, openFile, scope, readonly
}) => {
    const [objectInterface, setObjectInterface] = useState<TypeTemplateWrapper | null>(null);
    const [renderComponent, setRenderComponent] = useState<TypeTemplateWrapper | null>(null);
    const [itemComponent, setItemComponent] = useState<TypeTemplateWrapper | null>(null);
    const [skillComponent, setSkillComponent] = useState<TypeTemplateWrapper | null>(null);
    const [showResourceSelector, setShowResourceSelector] = useState<boolean>(false);
    const [resourceSelectorType, setResourceSelectorType] = useState<'model' | 'icon'>('model');
    const [resourceSelectorValue, setResourceSelectorValue] = useState<string>('');
    
    useEffect(() => {
        TypeTemplateWrapper.fromObject(() => value, setValue).then(async (obj) => {
            setObjectInterface(obj);

            if (!readonly && obj.setDefault([], "values", "components")) {
                console.error("Set default");
                return;
            }

            const components = obj.getWithDefault([], "values", "components");
            const name = value["name"];

            let hasRenderComponent = false;
            let hasItemComponent = false;
            
            for (let i = 0; i < components.length; i++) {
                const componentType = obj.getWithDefault(null, "values", "components", i, "type");
                const componentObj = await obj.getNestedObject("values", "components", i, "id");

                if (componentType === "RenderComponent") {
                    setRenderComponent(componentObj);

                    hasRenderComponent = true;
                }
                else if (componentType === "ItemComponent") {
                    setItemComponent(componentObj);

                    hasItemComponent = true
                }
                else if (componentType === "SkillComponent") {
                    setSkillComponent(componentObj);
                }
            }

            if (!readonly && !hasRenderComponent) {
                obj.addToArray({
                    "type": "RenderComponent",
                    "id": {
                        "type": "render-component",
                        "name": `${name}:render`,
                        "values": {

                        }
                    }
                }, "values", "components");
            }

            if (!readonly && !hasItemComponent) {
                obj.addToArray({
                    "type": "ItemComponent",
                    "id": {
                        "type": "item-component",
                        "name": `${name}:item`,
                        "values": {
                            "item-type": "special_r",
                            "base-value": 1000
                        }
                    }
                }, "values", "components");
            }
        });
    }, [value]);

    const onSelectResource = useCallback((type: 'model' | 'icon') => {
        setShowResourceSelector(true);
        setResourceSelectorType(type);

        if (type === 'model') {
            setResourceSelectorValue(renderComponent?.getObjectValue("values", "render-asset") ?? '');
        }
        else if (type === 'icon') {
            setResourceSelectorValue(renderComponent?.getObjectValue("values", "icon-asset") ?? '');
        }
    }, []);

    if (!objectInterface) {
        return null;
    }

    if (value["type"] !== "object") {
        return (
            <div>
                <GenericError title="Item view can only be used with objects" />
            </div>
        )
    }

    console.log(objectInterface, renderComponent, itemComponent, skillComponent);

    if (!renderComponent || !itemComponent) {
        return null;
    }

    const [itemTypeProperty, itemType] = itemComponent.getProperty("values", "item-type");

    const rarity = itemComponent.getWithDefault(0, "values", "rarity");

    const setRarity = (value: number) => {
        console.log("Set rarity", value);
        itemComponent.setObjectValue(value, "values", "rarity");
    }

    const englishName = objectInterface.getWithDefault("", "locales", "name");
    const englishDescription = objectInterface.getWithDefault("", "locales", "description");

    const isBop = itemComponent.getWithDefault(false, "values", "is-bop");
    const isBoe = itemComponent.getWithDefault(false, "values", "is-boe");

    const bindValue = isBop ? "bop" : isBoe ? "boe" : "nobind";

    const onSetBinding = (value: string) => {
        console.log("Set binding", value);

        if (value === "bop") {
            itemComponent.setObjectValue(true, "values", "is-bop");
            itemComponent.setObjectValue(true, "values", "is-boe");
        }
        else if (value === "boe") {
            itemComponent.setObjectValue(false, "values", "is-bop");
            itemComponent.setObjectValue(true, "values", "is-boe");
        }
        else {
            itemComponent.setObjectValue(false, "values", "is-bop");
            itemComponent.setObjectValue(false, "values", "is-boe");
        }
    }

    const bindingOptions = [
        { value: "bop", text: "Bind on Pickup", image: modService.getImageUrlResolvePack("lego-universe:ui/ingame/itemrollover_i75.dds") },
        { value: "boe", text: "Bind on Equip", image: modService.getImageUrlResolvePack("lego-universe:ui/ingame/itemrollover_i68.dds") },
        { value: "nobind", text: "No Binding", image: modService.getImageUrlResolvePack("lego-universe:ui/ingame/itemrollover_i78.dds") },
    ]

    return (
        <>
        <Row className="p-1">
            <Col md={12} sm={12} xl={8} xxl={8} className="inventory-view p-1">
                <Row>
                    <Col md={9}>
                        <div className="inventory-item-view">
                            <div>
                                <div className="inventory-item-name">
                                    <input className="inventory-text-input text-center m-0 w-100"
                                        value={englishName}
                                        onChange={(e) => objectInterface.setObjectValue(e.target.value, "locales", "name")}
                                        placeholder="Name"
                                    />
                                </div>
                                <div>
                                    <div className="row align-items-center">
                                        <div className="col-auto ms-auto me-auto">
                                            <span>
                                                <img src={modService.getImageUrlResolvePack(healthIcon)} alt="Health" width={32} height={32} />
                                                <span className="white-text ms-3">+</span>
                                                <input className="inventory-text-input"
                                                    type="number"
                                                    value={itemComponent.getObjectValue("values", "stack-size") ?? 0}
                                                    onChange={(e) => itemComponent.setObjectValue(Number(e.target.value), "values", "stack-size")}
                                                    size={3}
                                                />
                                            </span>
                                        </div>
                                        <div className="col-auto ms-auto me-auto">
                                            <span>
                                                <img src={modService.getImageUrlResolvePack(armourIcon)} alt="Armour" width={32} height={32} />
                                                <span className="white-text ms-3">+</span>
                                                <input className="inventory-text-input"
                                                    type="number"
                                                    value={itemComponent.getObjectValue("values", "stack-size") ?? 0}
                                                    onChange={(e) => itemComponent.setObjectValue(Number(e.target.value), "values", "stack-size")}
                                                    size={3}
                                                />
                                            </span>
                                        </div>
                                        <div className="col-auto ms-auto me-auto">
                                            <span>
                                                <img src={modService.getImageUrlResolvePack(imaginationIcon)} alt="Imagination" width={32} height={32} />
                                                <span className="white-text ms-3">+</span>
                                                <input className="inventory-text-input"
                                                    type="number"
                                                    value={itemComponent.getObjectValue("values", "stack-size") ?? 0}
                                                    onChange={(e) => itemComponent.setObjectValue(Number(e.target.value), "values", "stack-size")}
                                                    size={3}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="inventory-item-description">
                                    <input className="inventory-text-input text-center m-0 w-100"
                                        value={englishDescription}
                                        onChange={(e) => objectInterface.setObjectValue(e.target.value, "locales", "description")}
                                        placeholder="Description"
                                    />
                                </div>
                            </div>
                            <div className="inventory-item-view-divider"/>
                            <div className="row align-items-end rarity-row">
                                <div className="col-3 text-container">
                                    <span className="white-text">Rarity:</span>
                                </div>
                                <div className="col-6 diagonal-images">
                                    <div className="inventory-rarity-container inventory-rarity-justify" onClick={() => setRarity(1)}>
                                        <img src={modService.getImageUrlResolvePack((rarity >= 1) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-1"
                                        />
                                    </div>
                                    <div className="inventory-rarity-container" onClick={() => setRarity(2)}>
                                        <img src={modService.getImageUrlResolvePack((rarity >= 2) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-2"
                                        />
                                    </div>
                                    <div className="inventory-rarity-container" onClick={() => setRarity(3)}>
                                        <img src={modService.getImageUrlResolvePack((rarity >= 3) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-3"
                                        />
                                    </div>
                                    <div className="inventory-rarity-container" onClick={() => setRarity(4)}>
                                        <img src={modService.getImageUrlResolvePack((rarity >= 4) ? rarityFull : rarityEmpty)} alt="rarity"
                                            className="inventory-rarity-img-4"
                                        />
                                    </div>
                                    <div className="col-3">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="inventory-item-view-divider"/>
                            <div className="row">
                                <div className="col-auto me-3 inventory-coin-span">
                                    <img src={modService.getImageUrlResolvePack("lego-universe:ui/ingame/statusbar_i7.dds")} alt="coin"/>
                                    <span>
                                        <input className="inventory-text-input"
                                            type="number"
                                            value={itemComponent.getObjectValue("values", "base-value") / 10}
                                            onChange={(e) => itemComponent.setObjectValue(Number(e.target.value) * 10, "values", "base-value")} />
                                    </span>
                                </div>
                                <div className="col-auto ms-auto">
                                    <ImageDropdown
                                        items={bindingOptions}
                                        selectedValue={bindValue}
                                        onSelect={onSetBinding}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='inventory-item' onClick={() => onSelectResource('icon')}>
                            <img className="inventory-icon"
                                src={modService.getImageUrlResolvePack(renderComponent.getObjectValue("values", "icon-asset"))}
                                alt={renderComponent.getObjectValue("values", "icon-asset")}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col md={12} sm={12} xl={12} xxl={12}>
                <GenericInput
                    title="In-World Model"
                    value={renderComponent.getObjectValue("values", "render-asset")}
                    setValue={(value) => renderComponent.setObjectValue(value, "values", "render-asset")}
                    readonly={readonly}
                    type="text"
                />
                <GenericEnum
                    title="Item Type"
                    value={itemType}
                    setValue={(value) => itemComponent.setObjectValue(value, "values", "item-type")}
                    options={Object.keys(itemTypeProperty.enum ?? {})}
                    formatOption={(option) => FormatEnumOption(option)}
                    readonly={readonly}
                />
            </Col>
        </Row>
        <div>
            {showResourceSelector && (
                <ResourceExplorer
                    onSelect={(path, pack) => {
                        setShowResourceSelector(false);

                        if (resourceSelectorType === 'model') {
                            renderComponent.setObjectValue(`${pack}:${path}`, "values", "render-asset");
                        }
                        else if (resourceSelectorType === 'icon') {
                            renderComponent.setObjectValue(`${pack}:${path}`, "values", "icon-asset");
                        }
                    }}
                    pack={resourceSelectorValue.includes(':') ? resourceSelectorValue.split(':')[0] : 'lego-universe'}
                    // Do not include the pack or the filename
                    path={(resourceSelectorValue.includes(':') ? resourceSelectorValue.split(':')[1] : resourceSelectorValue).split('/').slice(0, -1).join('/')}
                    onHidden={() => {
                        setShowResourceSelector(false);
                    }}
                />
            )}
        </div>
        </>
    );
};